// Design Base Width
//------------------------------------------
$sp-design: 750;
$sp-viewport: 375;
$tab-design: 768;
$tab-viewport: 1000;

// Breakpoint
//------------------------------------------
$sp-min: 374px;
$sp: 767px;
$tablet-min: 768px;
$tablet: 1000px;
$pc: 1001px;

// Width & Height
//------------------------------------------
$max-width: 1366px;
$max-width-s: 1365px;
$min-width: 984px;

$header-height-sp:17.33333vw;
$header-height-pc:72px; 

// Base padding
//------------------------------------------
$side-padding-pc:48px;
$side-padding-sp:8vw;

// Color
//------------------------------------------
$main-color1:#0f74ca;
$main-color2:#1c35ad;
$main-color3:#002f7e;
$main-color4:#687b99;

// ヘッダーフッター
$global-color1:#2c477e;
$global-color2:#2f354d;
$global-color3:#232633;
$global-color4:#00225b;

// パーツのカラー

$text-color1: #1a1a1c;
$text-color2: #6a6a6a;

$bg-color1: #FFF;
$bg-color2: #f4f4f1;
$bg-color3:#ebf1f8;
$bg-color4:#F3F7F9;

$border-color1:#abbcce;
$border-color2:#cecece;
$border-color3:#c4c4c4;
$border-color4:#0074D1;

$caution-color1:#ef1914;
$caution-color2:#fcf1f0;

// Transition
//------------------------------------------
$transition-base: .35s ease-out;

// Font
//------------------------------------------
$font-base-family: YakuHanJP_Noto,'Barlow', 'Noto Sans JP','Yu Gothic Medium','游ゴシック Medium', YuGothic, '游ゴシック体', 'メイリオ', sans-serif;

$font-en1: 'Barlow', sans-serif;
