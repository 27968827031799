@import "../setting/import";

.page-top {

	.top-mv {
		margin-left:$side-padding-sp;
		padding-top: sp-vw(82);
		@include wide {
			padding-top: 50px;
			margin-left: $side-padding-pc;
		}
	}
	.top-mv__text {
		position: relative;
		z-index: 2;
		@include wide {
			margin-right: auto;
			margin-left: auto;
			width: calc(100% - 93px * 2);
		}
		em {
			display: block;
			color:$main-color3;
			font-size: 3.8rem;
			line-height: 1.1;
			font-weight: 700;
			letter-spacing: 0.04em;
			@include wide {
				font-size: 6.8rem;
				line-height: 1.2;
			}
		}
		span {
			display: block;
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 800;
			margin-top: sp-vw(18);
			@include wide {
				font-size: 2.8rem;
				margin-top: 8px;
				letter-spacing: 0.07em;
			}
		}
	}
	.top-mv__photo {
		background: url("/assets/img/top/bg_mv_sp.jpg") no-repeat right 45% top 50%;
		background-size: cover;
		margin-top: sp-vw(32);
		height: sp-vw(506);
		box-shadow: 20px 25px 32px 0px rgba(0, 0, 0, 0.2);
		@include wide {
			width: 100%;
			max-height: 580px;
			height: auto;
			aspect-ratio: 1283 / 580;
			box-shadow: 33px 44px 55px 0px rgba(0, 0, 0, 0.2);
			background-image: url("/assets/img/top/bg_mv_pc.jpg");
			margin-right: 0;
			margin-left: auto;
			margin-top: -123px;
		}
	}


	.top-about {
		padding: sp-vw(170) 0 0 0;
		@include wide {
			padding:125px 0 0 0; 
		}
	}
	.top-about__heading {
		margin-bottom: sp-vw(72);
		@include wide {
			margin-bottom: 35px;
		}
		span {
			color:$main-color3;
			font-size: 1.4rem;
			font-weight: 700;
			margin-top: sp-vw(15);
			@include wide {
				font-size: 1.8rem;
				margin-top: 10px;
			}
		}
	}
	.top-about__description {
		letter-spacing: 0.07em;
		text-align: justify;
		@include sp {
			font-size: 1.5rem;
			margin-top: sp-vw(28);
			width: sp-vw(550);
			margin-right: auto;
			margin-left: auto;
			line-height:2.2;
		}
		@include wide {
			font-size: 1.8rem;
			text-align: center;
			line-height:2.1;
		}
	}


	.top-news {
		padding-top: sp-vw(155);
		padding-bottom: sp-vw(120);
		@include wide {
			padding-top: 158px;
			padding-bottom: 120px;
		}
	}
	.top-news__inner {
		@include wide {
			display: flex;
		}
	}
	.top-news__heading {
		@include wide {
			width: 228px;
			margin-top: 5px;
		}
		.section-heading {
			@include wide {
				text-align: left;
				display: inline-block;
			}
		}
	}
	.top-news__body {
		@include sp {
			margin-top: sp-vw(55);
		}
		@include wide {
			flex:1;
		}
	}
	.top-news__button {
		margin-top: sp-vw(54);
		@include wide {
			margin-top: 28px;
		}
		.global-button {
			margin-left: auto;
			margin-right: auto;
			@include wide {
				margin-right: 0;
			}
		}
	}

	.top-news__banner {
		width: 68%;
		margin: sp-vw(48) auto 0;

		@include wide {
			max-width: 480px;
			height: 120px;
			margin: 70px auto 0;
		}
	}

	.top-news__banner-link {
		transition: opacity .3s;

		&:hover {
			@include wide {
				opacity: .7;
			}
		}
	}


	.top-estate {
		position: relative;
		padding:sp-vw(120) 0 0;
		margin-bottom:sp-vw(120);
		@include wide {
			padding:102px 0 0;
			margin-bottom: 120px;
		}
		&:after {
			content: '';
			display: block;
			background: $bg-color4;
			width: 100%;
			height: calc(100% - #{sp-vw(240)});
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			@include wide {
				width: calc(50% + 72px);
				height: calc(100% - 90px);
			}
		}
	}
	.top-estate__photo__item {
		width: sp-vw(315);
		margin-right: sp-vw(40);
		@include wide {
			width: 228px;
			margin-right: 24px;
		}
	}
	.top-estate__text-area {
		@include wide {
			max-width: 1153px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.top-estate__textbox {
		background: $bg-color1;
		position: relative;
		z-index: 2;
		@include sp {
			margin-left: $side-padding-sp;
			margin-top: sp-vw(80);
			box-shadow: 20px 26px 33px 0px rgba(0, 0, 0, 0.2);
			padding:sp-vw(75) sp-vw(80) sp-vw(80);
		}
		@include wide {
			box-shadow: 33px 44px 55px 0px rgba(0, 0, 0, 0.2);
			padding:78px 85px 80px;
			margin-top: 60px;
			margin-right: 0;
			margin-left: auto;
			width: calc(50% + 181px);
		}
		.section-heading {
			text-align: left;
		}
	}
	.top-estate__lead {
		font-size: 1.4rem;
		line-height: 2;
		margin-top: sp-vw(72);
		letter-spacing: 0.1em;
		text-align: justify;
		max-height: 100%; // Android Fontsize BugFix
		@include wide {
			font-size: 1.5rem;
			line-height: 1.93;
			margin-top: 25px;
		}
	}
	.top-estate__button {
		margin-top: sp-vw(50);
		@include wide {
			margin-top: 38px;
		}
	}


	.top-corporate {
		padding: sp-vw(125) 0 sp-vw(120);
		position: relative;
		@include wide {
			padding-top: 125px;
			padding-bottom: 120px;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;
			width: 100%;
			height: sp-vw(250);
			background: $bg-color4;	
			@include wide {
				height: 306px;
			}
		}
	}
	.top-corporate__heading {
		margin-bottom: sp-vw(72);
		@include wide {
			margin-bottom: 40px;
		}
	}

}// page end