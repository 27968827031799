// メディアクエリ
@mixin sp {
	@media (max-width: $sp) {
		@content;
	}
}
@mixin spmin {
	@media (max-width: $sp-min) {
		@content;
	}
}
@mixin wide {
	@media (min-width: $tablet-min) {
		@content;
	}
}
@mixin widemax {
	@media (min-width: $max-width) {
		@content;
	}
}

// テキストを隠す
@mixin texthidden {
	white-space: nowrap;
	text-indent: 100%;
	overflow: hidden;
	font-size: 0;
	line-height: 0;
}

// sp vw計算
@function sp-vw($target) {
	@return ($target / $sp-design) * 100vw;
}

// ブラウザハック
@mixin hack_edge {
  @at-root _:-ms-lang(x)::backdrop, & {
    @content;
  }
}

// グラデーション
@mixin main_gradient {
  background-image: linear-gradient( 45deg, $main-color1 0%, $main-color2 100%);
}
@mixin main_gradient_reverse {
  background-image: linear-gradient( 45deg, $main-color2 0%, $main-color1 100%);
}