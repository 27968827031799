.page-top .top-mv {
  margin-left: 8vw;
  padding-top: 10.93333vw;
}

@media (min-width: 768px) {
  .page-top .top-mv {
    padding-top: 50px;
    margin-left: 48px;
  }
}

.page-top .top-mv__text {
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .page-top .top-mv__text {
    margin-right: auto;
    margin-left: auto;
    width: calc(100% - 93px * 2);
  }
}

.page-top .top-mv__text em {
  display: block;
  color: #002f7e;
  font-size: 3.8rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: 0.04em;
}

@media (min-width: 768px) {
  .page-top .top-mv__text em {
    font-size: 6.8rem;
    line-height: 1.2;
  }
}

.page-top .top-mv__text span {
  display: block;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 800;
  margin-top: 2.4vw;
}

@media (min-width: 768px) {
  .page-top .top-mv__text span {
    font-size: 2.8rem;
    margin-top: 8px;
    letter-spacing: 0.07em;
  }
}

.page-top .top-mv__photo {
  background: url("/assets/img/top/bg_mv_sp.jpg") no-repeat right 45% top 50%;
  background-size: cover;
  margin-top: 4.26667vw;
  height: 67.46667vw;
  box-shadow: 20px 25px 32px 0px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .page-top .top-mv__photo {
    width: 100%;
    max-height: 580px;
    height: auto;
    aspect-ratio: 1283 / 580;
    box-shadow: 33px 44px 55px 0px rgba(0, 0, 0, 0.2);
    background-image: url("/assets/img/top/bg_mv_pc.jpg");
    margin-right: 0;
    margin-left: auto;
    margin-top: -123px;
  }
}

.page-top .top-about {
  padding: 22.66667vw 0 0 0;
}

@media (min-width: 768px) {
  .page-top .top-about {
    padding: 125px 0 0 0;
  }
}

.page-top .top-about__heading {
  margin-bottom: 9.6vw;
}

@media (min-width: 768px) {
  .page-top .top-about__heading {
    margin-bottom: 35px;
  }
}

.page-top .top-about__heading span {
  color: #002f7e;
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 2vw;
}

@media (min-width: 768px) {
  .page-top .top-about__heading span {
    font-size: 1.8rem;
    margin-top: 10px;
  }
}

.page-top .top-about__description {
  letter-spacing: 0.07em;
  text-align: justify;
}

@media (max-width: 767px) {
  .page-top .top-about__description {
    font-size: 1.5rem;
    margin-top: 3.73333vw;
    width: 73.33333vw;
    margin-right: auto;
    margin-left: auto;
    line-height: 2.2;
  }
}

@media (min-width: 768px) {
  .page-top .top-about__description {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2.1;
  }
}

.page-top .top-news {
  padding-top: 20.66667vw;
  padding-bottom: 16vw;
}

@media (min-width: 768px) {
  .page-top .top-news {
    padding-top: 158px;
    padding-bottom: 120px;
  }
}

@media (min-width: 768px) {
  .page-top .top-news__inner {
    display: flex;
  }
}

@media (min-width: 768px) {
  .page-top .top-news__heading {
    width: 228px;
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .page-top .top-news__heading .section-heading {
    text-align: left;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .page-top .top-news__body {
    margin-top: 7.33333vw;
  }
}

@media (min-width: 768px) {
  .page-top .top-news__body {
    flex: 1;
  }
}

.page-top .top-news__button {
  margin-top: 7.2vw;
}

@media (min-width: 768px) {
  .page-top .top-news__button {
    margin-top: 28px;
  }
}

.page-top .top-news__button .global-button {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .page-top .top-news__button .global-button {
    margin-right: 0;
  }
}

.page-top .top-news__banner {
  width: 68%;
  margin: 6.4vw auto 0;
}

@media (min-width: 768px) {
  .page-top .top-news__banner {
    max-width: 480px;
    height: 120px;
    margin: 70px auto 0;
  }
}

.page-top .top-news__banner-link {
  transition: opacity .3s;
}

@media (min-width: 768px) {
  .page-top .top-news__banner-link:hover {
    opacity: .7;
  }
}

.page-top .top-estate {
  position: relative;
  padding: 16vw 0 0;
  margin-bottom: 16vw;
}

@media (min-width: 768px) {
  .page-top .top-estate {
    padding: 102px 0 0;
    margin-bottom: 120px;
  }
}

.page-top .top-estate:after {
  content: '';
  display: block;
  background: #F3F7F9;
  width: 100%;
  height: calc(100% - 32vw);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@media (min-width: 768px) {
  .page-top .top-estate:after {
    width: calc(50% + 72px);
    height: calc(100% - 90px);
  }
}

.page-top .top-estate__photo__item {
  width: 42vw;
  margin-right: 5.33333vw;
}

@media (min-width: 768px) {
  .page-top .top-estate__photo__item {
    width: 228px;
    margin-right: 24px;
  }
}

@media (min-width: 768px) {
  .page-top .top-estate__text-area {
    max-width: 1153px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-top .top-estate__textbox {
  background: #FFF;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .page-top .top-estate__textbox {
    margin-left: 8vw;
    margin-top: 10.66667vw;
    box-shadow: 20px 26px 33px 0px rgba(0, 0, 0, 0.2);
    padding: 10vw 10.66667vw 10.66667vw;
  }
}

@media (min-width: 768px) {
  .page-top .top-estate__textbox {
    box-shadow: 33px 44px 55px 0px rgba(0, 0, 0, 0.2);
    padding: 78px 85px 80px;
    margin-top: 60px;
    margin-right: 0;
    margin-left: auto;
    width: calc(50% + 181px);
  }
}

.page-top .top-estate__textbox .section-heading {
  text-align: left;
}

.page-top .top-estate__lead {
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 9.6vw;
  letter-spacing: 0.1em;
  text-align: justify;
  max-height: 100%;
}

@media (min-width: 768px) {
  .page-top .top-estate__lead {
    font-size: 1.5rem;
    line-height: 1.93;
    margin-top: 25px;
  }
}

.page-top .top-estate__button {
  margin-top: 6.66667vw;
}

@media (min-width: 768px) {
  .page-top .top-estate__button {
    margin-top: 38px;
  }
}

.page-top .top-corporate {
  padding: 16.66667vw 0 16vw;
  position: relative;
}

@media (min-width: 768px) {
  .page-top .top-corporate {
    padding-top: 125px;
    padding-bottom: 120px;
  }
}

.page-top .top-corporate:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 33.33333vw;
  background: #F3F7F9;
}

@media (min-width: 768px) {
  .page-top .top-corporate:after {
    height: 306px;
  }
}

.page-top .top-corporate__heading {
  margin-bottom: 9.6vw;
}

@media (min-width: 768px) {
  .page-top .top-corporate__heading {
    margin-bottom: 40px;
  }
}
